import React, { useContext, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { getToken } from '../../../apiCommandsExecutor/token';
import { generateClientSyncId } from '../../../apiCommandsExecutor';
import { useStores } from '../../../hooks';
import TelephonyStoreContext from '../../../modules/maintainer/pages/Telephony/store/context';
import { IncomingCallSnackbar } from '../../../modules/maintainer/pages/Telephony/Snackbar/IncomingCallSnackbar';

const Websockets = () => {
    const { generalStore } = useStores();
    const orgBranchId = generalStore.currentOrganizationBranch?.id;
    const socketRef = useRef<any>(null);
    const token = getToken();
    const telephonyStore = useContext(TelephonyStoreContext);

    useEffect(() => {
        if (!orgBranchId) return;
        const connect = () => {
            socketRef.current = new WebSocket(
                `wss://wisecity.systems/execute/client?AuthToken=${token}`,
            );
            socketRef.current.onopen = (ev: Event) => {
                console.log('ws connected', ev);
                socketRef.current.send(
                    JSON.stringify({
                        clientSyncId: generateClientSyncId(),
                        subscriptionEvents: [
                            {
                                clientEvent: 'PreSupportRequestCreateByCall',
                                orgBranchIds: [orgBranchId],
                                houseIds: [],
                            },
                        ],
                        method: 'WebsocketSystemObjects.SubscribeToClientEvents',
                    }),
                );
            };

            socketRef.current.onmessage = (ev: MessageEvent) => {
                if (ev.type === 'message') {
                    try {
                        const response = JSON.parse(ev.data);
                        if (response['callerPhoneNumber']) {
                            telephonyStore.setWsCallerInfo(response);
                        }
                    } catch (err) {
                        console.log(err);
                    }
                }
            };

            socketRef.current.onerror = (err: Event) => {
                console.log('ws error', err);
            };

            socketRef.current.onclose = (ev: CloseEvent) => {
                console.log('ws disconnected', ev);
                reconnect();
            };
        };

        const reconnect = () => {
            setTimeout(() => {
                connect();
            }, 3000);
        };

        connect();

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
        };
    }, [orgBranchId]);

    return (
        <div>
            <IncomingCallSnackbar />
        </div>
    );
};

export default observer(Websockets);
